<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-actions"></div>
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-4">
            <a-form-item label="项目">
              <a-select
                class="form-control lg"
                v-decorator="['projectCode']"
                placeholder="请选择"
                showSearch
                :options="projectList"
                :filterOption="filterByPY"
                @change="onSelectProject"
              ></a-select>
            </a-form-item>
            <a-form-item label="班组">
              <a-select
                class="form-control lg"
                v-decorator="['leaderId']"
                placeholder="请选择"
                allowClear
                showSearch
                :filterOption="filterByPY"
                :options="leaderList"
              ></a-select>
            </a-form-item>
            <a-form-item label="工人">
              <a-input class="form-control" v-decorator="['workerName']" placeholder="请输入工人姓名"></a-input>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-main">
          <div class="qv-table-layout-h">
            <div class="qv-table-container">
              <a-table
                class="queryView-table"
                rowKey="id"
                bordered
                :loading="loading"
                :columns="tableColumns"
                :dataSource="dataSource"
                :pagination="false"
                size="middle"
                :rowSelection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onRowSelectChange,
                  type: 'radio',
                  columnWidth: '2%',
                }"
              ></a-table>
              <base-pagination
                :totalCount.sync="totalCount"
                :pageNo.sync="pageNo"
                :pageSize.sync="pageSize"
                @change="pageChange"
                size="small"
              />
            </div>
            <div class="qv-table-container">
              <AtendenceDetail
                :userId="attendenceWorkerIds[0]"
                :projectCode="form.getFieldValue('projectCode')"
                :leaderList="leaderListExcludeNo"
              ></AtendenceDetail>
            </div>
          </div>
        </div>
      </div>
    </a-card>
    <!-- 添加/编辑弹框 -->
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form :form="modal.form" v-bind="modalFormLayout">
        <a-form-item label="原班组">{{ modal.data.leaderName }}{{ modal.data.leaderCardNumber }}</a-form-item>
        <a-form-item label="最新班组">
          <a-select
            class="form-control lg"
            v-decorator="['leaderId']"
            placeholder="请选择"
            allowClear
            :options="leaderListExcludeNo"
          ></a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import { getProjectList, getLeaderList } from '@/service/getData'
import AtendenceDetail from './modules/AtendenceDetail'
export default {
  components: {
    AtendenceDetail,
  },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '工友',
        width: '18%',
        align: 'center',
        dataIndex: 'workerName',
      },
      {
        title: '班组',
        width: '18%',
        align: 'center',
        dataIndex: 'leaderName',
      },
      {
        title: '操作',
        width: '20%',
        align: 'center',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          return (
            <div>
              <span>
                <a
                  class="row-action-btn"
                  onClick={() => {
                    this.edit(row)
                  }}
                >
                  班组变更
                </a>
              </span>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },

      projectList: [],
      leaderList: [],
      workerList: [],
    }
  },
  computed: {
    typesMap() {
      return {
        state: [
          { value: 1, label: '启用' },
          { value: 0, label: '停用' },
        ],
        bverify: [
          { value: 1, label: '完成' },
          { value: 2, label: '未审核' },
          { value: 0, label: '驳回' },
        ],
      }
    },
    attendenceWorkerIds() {
      return this.selectedRows.map(row => {
        return row.workerId
      })
    },
    leaderListExcludeNo() {
      return this.leaderList.filter(row => {
        return row.value !== 'NO_LEADER'
      })
    },
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      this.onRowSelectChange([], [])
      try {
        const res = await this.$axios.post('/attendance/web/leader/query', {
          ...this.getPageParams(_pageNo, _pageSize),
        })
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
        if (pageNo === 1 && this.dataSource.length) {
          const row = this.dataSource[0]
          this.onRowSelectChange([row.id], [row])
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async edit(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '班组变更'
      this.modal.data = { ...row }
    },

    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            // "leaderId": "string",
            // "projectCode": "string",
            // "workerId": "string"

            const actionUrl = '/attendance/web/leader/change'
            const { workerId } = this.modal.data
            const projectCode = this.form.getFieldValue('projectCode')
            await this.$axios.post(
              actionUrl,
              {
                ...params,
                projectCode,
                workerId,
              },
              {
                postDataType: 'json',
              }
            )
            this.$message.success('操作成功!')
            this.resetModal()
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    async onSelectProject(projectCode) {
      this.form.setFieldsValue({ leaderId: undefined })
      this.leaderList = await getLeaderList(projectCode)
    },
    onRowSelectChange(keys, rows) {
      this.selectedRowKeys = keys
      this.selectedRows = rows
    },
  },
  async mounted() {
    this.projectList = await getProjectList()
    const defaultProject = this.projectList.length ? this.projectList[0].value : ''
    if (defaultProject) {
      this.form.setFieldsValue({ projectCode: defaultProject })
      this.leaderList = await getLeaderList(defaultProject)
    }
  },
}
</script>

<style lang="less" scoped>
.qv-table-layout-h {
  display: grid;
  grid-template-columns: 30% 1fr;
  gap: 24px;
}
</style>
