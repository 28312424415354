<template>
  <div>
    <a-form layout="inline" style="padding-bottom: 10px">
      <a-form-item>
        <a-button type="primary" @click="batchEdit(selectedRows)">批量修改</a-button>
      </a-form-item>
      <a-form-item label="考勤月份">
        <a-select
          class="form-control mutiple-select"
          :options="monthOptions"
          v-model="months"
          mode="multiple"
          placeholder="选择考勤月份"
        ></a-select>
      </a-form-item>
    </a-form>
    <a-table
      class="queryView-table"
      rowKey="id"
      bordered
      :loading="loading"
      :columns="tableColumns"
      :dataSource="dataSource"
      :pagination="false"
      :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onRowSelectChange,
        type: 'checkbox',
        columnWidth: '2%',
      }"
      size="middle"
    ></a-table>
    <base-pagination
      size="small"
      :totalCount.sync="totalCount"
      :pageNo.sync="pageNo"
      :pageSize.sync="pageSize"
      @change="pageChange"
    />
    <!-- 添加/编辑弹框 -->
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form :form="modal.form" v-bind="modalFormLayout">
        <!-- 操作单条row的时候 -->
        <template v-if="modal.data.id">
          <a-form-item label="考勤日期">{{ modal.data.attendanceDate }}</a-form-item>
          <a-form-item label="原班组">{{ modal.data.leaderName }}{{ modal.data.leaderCardNumber }}</a-form-item>
        </template>

        <a-form-item label="最新班组">
          <a-select
            class="form-control lg"
            v-decorator="['leaderId']"
            placeholder="请选择"
            allowClear
            :options="leaderList"
          ></a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { queryViewMixin } from '@/utils/mixin'
import moment from 'moment'
export default {
  mixins: [queryViewMixin],
  props: {
    userId: {
      require: true,
      type: String,
    },
    leaderList: {
      require: true,
      type: Array,
    },
    projectCode: {
      require: true,
      type: String,
    },
  },
  data() {
    const tableColumns = [
      {
        title: '考勤日期',
        width: '15%',
        align: 'center',
        dataIndex: 'attendanceDate',
      },
      {
        title: '最新考勤班组',
        width: '20%',
        align: 'center',
        customRender: (text, row, index) => {
          return (
            <div>
              {row.leaderName}
              {row.leaderCardNo}
            </div>
          )
        },
      },
      {
        title: '操作文员',
        width: '10%',
        align: 'center',
        dataIndex: 'operator',
      },
      {
        title: '操作时间',
        width: '15%',
        align: 'center',
        dataIndex: 'createTime',
      },
      {
        title: '操作',
        width: '15%',
        align: 'center',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          return (
            <div>
              <span>
                <a
                  class="row-action-btn"
                  onClick={() => {
                    this.edit(row)
                  }}
                >
                  考勤修改
                </a>
              </span>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],
      months: [moment().format('YYYY-MM')],
      selectedRowKeys: [],
      selectedRows: [],
      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        // data里面，多条的信息，一般不包含id字段（通过id区分是批量操作还是单条操作）。单条情况下可以 === 原始数据列
        data: {},
        // 需要批量操作需要原始数据列的时候使用。批量操作的原始数据列一般情况下是selectedRows的子集
        rows: [],
      },
    }
  },
  watch: {
    userId: {
      handler(v) {
        this.conditionalQuery()
      },
      immediate: true,
    },
    months() {
      this.conditionalQuery()
    },
  },
  computed: {
    monthOptions() {
      const result = []
      for (let i = 0; i < 6; i++) {
        const month = moment()
          .subtract(i, 'month')
          .format('YYYY-MM')
        result.push({
          label: month,
          value: month,
        })
      }
      return result
    },
  },
  methods: {
    onRowSelectChange(keys, rows) {
      this.selectedRowKeys = keys
      this.selectedRows = rows
    },
    conditionalQuery() {
      if (this.userId) {
        this.doQuery()
      } else {
        this.resetTable()
      }
    },
    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            const actionUrl = '/attendance/web/updateAttendanceList'
            const ids = this.modal.rows
            await this.$axios.post(
              actionUrl,
              {
                ids: ids,
                ...params,
              },
              {
                postDataType: 'json',
              }
            )
            this.$message.success('操作成功!')
            // 关闭弹框
            this.modal.visible = false
            // 重置勾选
            this.onRowSelectChange([], [])
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    async edit(row) {
      this.batchEdit([row])
    },
    async batchEdit(rows) {
      this.resetModal()
      await this.checkBatchActionRows(rows)
      this.modal.visible = true
      this.modal.title = '考勤修改'
      this.modal.data = {}
      this.modal.rows = rows
    },
    getMonthListFromRange([startMoment, endMoment]) {
      const result = []
      if (startMoment && endMoment) {
        const count = endMoment.diff(startMoment, 'month')

        result.push(startMoment)
        for (let i = 1; i < count; i++) {
          result.push(moment(startMoment).add(i, 'month'))
        }
        result.push(endMoment)
      }

      return result.map(item => {
        return item.format('YYYY-MM')
      })
    },
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/attendance/web/getAttendanceList', {
          ...this.getPageParams(_pageNo, _pageSize),
          dateList: this.months,
          userId: this.userId,
          projectCode: this.projectCode,
        })
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    resetTable() {
      this.pageNo = 1
      this.totalCount = 0
      this.dataSource = []
    },
  },
  mounted() {},
}
</script>
